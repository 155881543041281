const newseLatterData = [
	{
		id: '1',
		icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: 'icon-wallet' },
		text: ['Stealth Launch',
    'CoinGecko/Coinmarketcap Listings',
    '1,000+ Holders',
    'Trend $OTAK on social media',
    'Play games and watch anime'
  ],
	},
	{
		id: '2',
		icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-wallet' },

		text: [
      'Community Partnerships',
'More spread on socials',
'CEX Listings',
'10,000+ holders',
'Vibe vibe vibe'
    ],
	},
	{
		id: '3',
		icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-gallery' },
		text: [
      'Go global!',
'Otaku Merch',
'Otaku Boardgames',
'Otaku Pop ',
'Listing on T1 Exchanges',
'100,000+ holders',
    ],
	},
];

export { newseLatterData };
